import styled from "styled-components"

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 7px;
    background: ${props => props.theme.colors.primary.green};
    width: 2px;
    height: 2px;
    box-shadow: 1px 0 0 ${props => props.theme.colors.primary.green},
      2px 0 0 ${props => props.theme.colors.primary.green},
      2px -1px 0 ${props => props.theme.colors.primary.green},
      2px -3px 0 ${props => props.theme.colors.primary.green},
      2px -4px 0 ${props => props.theme.colors.primary.green},
      2px -6px 0 ${props => props.theme.colors.primary.green};
    transform: rotate(45deg);
  }
`

export default Input
