import styled from "styled-components"

const StyledForm = styled.form`
  display: grid;
  grid-gap: 24px;
  max-width: 250px;
  margin: auto;
  justify-items: center;
  margin-top: 32px;
`

export default StyledForm
