import styled from "styled-components"

const Wrapper = styled.div`
  margin-top: ${props => props.top};
  margin-bottom: ${props => props.bottom};
  margin-left: ${props => props.left};
  margin-right: ${props => props.right};
`

export default Wrapper
