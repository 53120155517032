import styled from "styled-components"

const StyledCheckbox = styled.label`
  position: relative;
  cursor: pointer;
  padding: 0;

  &:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    border: 2px solid ${props => props.theme.colors.primary.green};
    border-radius: 2px;
  }
`

export default StyledCheckbox
