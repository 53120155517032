import React from "react"

//React components imports
import Layout from "../components/Layout"
import SignUpForm from "../components/SignUpForm"
import PageTitle from "../components/PageTitle"

import ReservationText from "../components/ReservationText"

const SignInPage = props => {
  return (
    <Layout>
      {/* <PageTitle subtitle="Registrácia">Rezervácia</PageTitle>
      <SignUpForm /> */}
      <ReservationText />
    </Layout>
  )
}

export default SignInPage
