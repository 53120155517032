import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const white = css`
  color: white;
  &:hover {
    filter: brightness(85%);
  }
`

const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.primary.green};
  font-weight: ${props => (props.bold ? "bold" : "normal")};

  ${props => props.white && white};
`

export default Link
