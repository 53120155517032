import styled from "styled-components"

const Text = styled.div`
  text-align: center;
  margin: ${props => props.top || "24px"} auto 0;
  font-weight: normal;
  line-height: 23px;
`

export default Text
